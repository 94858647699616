// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const MUTATION = gql`
  mutation ExecuteDossierBlockAction(
    $actionId: ID!,
    $selectedIds: [ID!],
    $navigationSlug: String!,
    $dossierTypeSlug: String!,
    $blockId: ID!,
    $dossierId: ID!,
    $context: String,
  ) {
    successful: executeDossierBlockAction(
      actionId: $actionId,
      ids: $selectedIds,
      navigationSlug: $navigationSlug,
      dossierTypeSlug: $dossierTypeSlug,
      blockId: $blockId,
      dossierId: $dossierId,
      context: $context,
    )
  }
`;

export default MUTATION;
